var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunkFull"
  }, [_c('toastMsg', {
    ref: "toast"
  }), _vm.isLoading ? _c('spinner') : _vm._e(), _c('header', {
    staticClass: "DAV-topBar"
  }, [_c('h2', {
    staticClass: "DAV-topBar__theme-name"
  }, [_vm._v("컴포넌트 미리보기")]), _c('div', {
    staticClass: "DAV-topBar__device"
  }, [_c('button', {
    staticClass: "DAV-topBar__device-pc",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'desktop'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('desktop');
      }
    }
  }, [_vm._v(" pc ")]), _c('button', {
    staticClass: "DAV-topBar__device-mobile",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'mobile'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('mobile');
      }
    }
  }, [_vm._v(" mobile ")])]), _c('button', {
    staticClass: "DAV-topBar--close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" 컴포넌트 미리보기 닫기 ")])]), _c('div', {
    staticClass: "DAV-preview"
  }, [_c('div', {
    staticClass: "DAV-preview__shop",
    class: _vm.setPreviewClass
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0"
    },
    attrs: {
      "id": "component-preview",
      "src": _vm.url,
      "scrolling": "no"
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }