<template>
  <div class="DAV-workshop__trunkFull">
    <toastMsg ref="toast" />
    <spinner v-if="isLoading" />
    <header class="DAV-topBar">
      <h2 class="DAV-topBar__theme-name">컴포넌트 미리보기</h2>
      <div class="DAV-topBar__device">
        <button
          class="DAV-topBar__device-pc"
          :class="{'DAV-topBar__device--active': deviceType === 'desktop'}"
          @click="setDeviceType('desktop')"
        >
          pc
        </button>
        <button
          class="DAV-topBar__device-mobile"
          :class="{'DAV-topBar__device--active': deviceType === 'mobile'}"
          @click="setDeviceType('mobile')"
        >
          mobile
        </button>
      </div>
      <button class="DAV-topBar--close" @click="close">
        컴포넌트 미리보기 닫기
      </button>
    </header>
    <div class="DAV-preview">
      <div class="DAV-preview__shop" :class="setPreviewClass">
        <iframe
          id="component-preview"
          :src="url"
          style="width: 100%; height: 100%; border: 0"
          scrolling="no"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleComponent = createNamespacedHelpers("ModuleComponent");

export default {
  data() {
    return {
      deviceType: null,
      isLoading: false
    };
  },
  computed: {
    // class binding - 디바이스 타입
    setPreviewClass() {
      return {
        "DAV-preview__shop--mobile": this.deviceType === "mobile",
        "DAV-preview__shop--desktop": this.deviceType === "desktop"
      };
    },
    url() {
      return this.getMode === "partner"
        ? `/preview/${this.getThemeId}?mode=partner`
        : `/preview/${this.getThemeId}`;
    },
    ...ModuleEditor.mapGetters(["getThemeId", "getComponentId", "getMode"])
  },
  mounted() {
    // 디바이스 선택
    this.deviceType =
      document.documentElement.clientWidth < 1024 ? "mobile" : "desktop";

    // 컴포넌트 불러오기
    this.componentLoad();
  },
  methods: {
    // 컴포넌트 미리보기 닫기
    close() {
      if (this.$route.name === "intro-component-preview") {
        window.close();
      } else {
        this.setPopup({page: null});
      }
    },
    // 컴포넌트 load
    componentLoad() {
      let payload = {};
      payload.componentId = this.getComponentId;
      this.isLoading = true;
      this.actComponentLoad(payload).then(response => {
        const componentContainer = new Map();
        componentContainer.set(1, response.data);
        setTimeout(() => {
          document
            .getElementById("component-preview")
            .contentWindow.postMessage(
              {
                action: "component",
                device: this.deviceType,
                componentContainer
              },
              `${window.location.origin}/preview`
            );
        }, 1000);
        this.isLoading = false;
        if (this.$route.name === "intro-component-preview") {
          setTimeout(() => {
            this.$refs.toast.show(
              "컴포넌트에 사용된 폰트 스타일은 에디터에서 설정할 수 있습니다."
            );
          }, 1000);
        }
      });
    },
    // 디바이스 선택
    setDeviceType(deviceType) {
      this.deviceType = deviceType;
      document
        .getElementById("component-preview")
        .contentWindow.postMessage(
          {action: "device", device: this.deviceType},
          `${window.location.origin}/preview`
        );
    },

    ...ModuleEditor.mapMutations(["setPopup"]),
    ...ModuleComponent.mapActions(["actComponentLoad"])
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue"),
    toastMsg: () => import("@/components/accounts/toast-message.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunkFull {
  z-index: 12;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;
}
.DAV-preview {
  position: fixed;
  top: 56px;
  right: 0px;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;

  &__shop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  &__shop--desktop {
    margin: 8px;
  }
}
.DAV-topBar__theme-name {
  padding-left: 16px;
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-preview__shop--mobile {
    width: 421px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-preview__shop {
    &--desktop {
      margin: 0;
    }
  }
  .DAV-topBar__device {
    right: calc(50% - 36px);
  }
}
</style>
